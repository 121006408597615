import { withRouter } from 'react-router-dom'
import React from "react";
import {ViewHeatAsAdmin} from "./view_heat_as_admin";

export function ViewHeatAsMontage() {
    return (
        <ViewHeatAsAdmin source={'montage'}/>
    )
}

export default withRouter(ViewHeatAsMontage);

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "../App.css"

export default function WarehouseAddModal(props) {
    const [warehouseOrderItemData, setWarehouseOrderItemData] = useState([]);
    const [test, setTest] = useState(true)

    const setShow = (data) => {
        props.passChildData(data);
    }

    const cleanup = () => {
        var tempArr = [];
        for (var i = 0; i < warehouseOrderItemData.length; i++) {
            if (warehouseOrderItemData[i].assigned != 0) {
                tempArr.push(warehouseOrderItemData[i]);
            }
        }
        props.getAddedItems(tempArr)
        props.setModalData(warehouseOrderItemData)
        setWarehouseOrderItemData([]);
        setShow(false);
    }


    const createOrderArray = (array) => {
        let tempArr = [];
        for (var i = 0; i < array.length; i++) {
            if (array[i].assigned) {
                tempArr.push(orderItem);
            } else {
                var orderItem = array[i];
                orderItem.assigned = 0
                tempArr.push(orderItem);
            }

        }
        setWarehouseOrderItemData(tempArr);
    }

    const addItemToOrder = (item) => {
        var tempArr = warehouseOrderItemData;
        const index = tempArr.findIndex(({ id }) => parseInt(id) === parseInt(item.id));
        tempArr[index].assigned = tempArr[index].assigned + 1
        tempArr[index].quantity = parseInt(tempArr[index].quantity) - 1
        setWarehouseOrderItemData(tempArr)
        setTest(!test);
    }

    const deleteItemFromOrder = (item) => {
        var tempArr = warehouseOrderItemData;
        const index = tempArr.findIndex(({ id }) => id === item.id);
        if (tempArr[index].assigned > 0) {
            tempArr[index].assigned = tempArr[index].assigned - 1
            tempArr[index].quantity = parseInt(tempArr[index].quantity) + 1
        }
        setWarehouseOrderItemData(tempArr)
        setTest(!test)
    }


    const saveOrderWarehouse = () => {
        var tempArr = [];
        for (var i = 0; i < warehouseOrderItemData.length; i++) {
            if (warehouseOrderItemData[i].assigned != 0) {
                tempArr.push(warehouseOrderItemData[i]);
            }
        }
        console.log(tempArr);
        props.getAddedItems(tempArr);
        setShow(false)
    }


    useEffect(() => {
        console.log(props.modalData);
        createOrderArray(props.modalData);
        setWarehouseOrderItemData(props.modalData);
        setTest(!test)
    }, [props.show])

    const renderWarehouseElements = () => {
        return (
            warehouseOrderItemData.map((item, index) => {
                return (
                    <div key={`modal-item-${index}`} className={`row ${index === 0 ? "border-modal-both" : "border-modal"}`}>
                        <div className="col-sm row mx-0 mt-3">
                            <p>Nazwa części: {item.name}</p>
                            <p key={test} className="ml-4">Dostępne: {item.quantity}</p>
                        </div>
                        <div className="col-sm row">

                            <Button className="mb-2 mt-2 d-flex justify-content-center align-items-center" variant="success" size='sm' onClick={() => addItemToOrder(item)}>Dodaj</Button>
                            <p key={test} className="mx-1 mt-3">Przypisane: {item.assigned}</p>
                            <Button className="mb-2 mt-2 d-flex justify-content-center align-items-center" variant="danger" size='sm' onClick={() => deleteItemFromOrder(item)}>Odejmij</Button>
                        </div>
                    </div>



                )
            })
        )
    }

    return (
        <Modal animation={false} scrollable={true} show={props.show} onHide={() => cleanup()} dialogClassName="w90" centered>
            <Modal.Header closeButton>
                <Modal.Title>{props.modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container overflow-y">
                    <div className="row">
                        <div className="col-sm">
                            <h2>MAGAZYN</h2>
                        </div>
                        <div className="col-sm">
                            <h2 >PRZYPISANE</h2>
                        </div>
                    </div>
                    {renderWarehouseElements()}
                    <Button className="mb-2 mt-2 d-flex justify-content-center align-items-center" variant="success" size='lg' onClick={() => saveOrderWarehouse()}>Zapisz</Button>

                </div>
            </Modal.Body>
        </Modal>

    );
};



